import React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import PageHeader from '../components/PageHeader';
import UnitHeader from '../components/UnitHeader';
import { StockCheck, StockQuantity } from '../components/UnitStockCheckList';
import { Button, Table, TextInput } from 'flowbite-react';
import Swal from 'sweetalert2';

const StockCheckPage: React.FC = () => {
    const params = useParams();
    const navigate = useNavigate();
    const checklistId = params.checklistId;

    const [stockCheck, setStockCheck] = useState<StockCheck | null>(null);
    const [search, setSearch] = useState('');
    // Fetch Stock Check
    useEffect(() => {
        const fetchStockCheck = async () => {
            try {
                const response = await axios.get(`/stock_checks/${checklistId}`);
                setStockCheck(response.data);
            } catch (err) {
                console.log("Check Error", err);
            }
        };
        if (checklistId) {
            fetchStockCheck();
        }
    }, [checklistId]);

    const saveQuantity = (stockQuantity: StockQuantity, quantity: number, index: number) => {
        axios.patch(
            `stock_checks/${stockCheck?.id}/quantities/${stockQuantity.id}`,
            { quantity }, {
            headers: {
                'Content-Type': 'application/merge-patch+json'
            }
        }
        ).then((response) => {
            if (stockCheck && stockCheck.stockQuantities[index]) {
                stockCheck.stockQuantities[index].totalPrice = response.data.totalPrice;
                setStockCheck({ ...stockCheck });
            }
        });
    }

    let title = stockCheck ? `${stockCheck.category.name}` : '';
    let totalPrice = stockCheck?.stockQuantities.reduce((sum: any, stockQuantity) => {
        return sum + stockQuantity.totalPrice;
    }, 0);
    return (
        <div className="flex min-h-full flex-col px-4 lg:px-8 ">
            <div className="page-center">
                <PageHeader title={title} />
                <UnitHeader unitData={stockCheck?.unit} tab='stock-check' />
                <div className="page-body h-question relative mt-[110px]  mb-[2.5px] pt-4 overflow-auto">
                    <fieldset className="flex flex-col gap-3 mb-4 p-2">
                        <input
                            type="text"
                            value={search}
                            onChange={(event) => {
                                setSearch(event.target.value);
                            }}
                            placeholder="Search"
                            className="h-[40px] text-[13px] bg-sawhite text-sablack border border-[#B1B1B1] w-full rounded-lg px-3 placeholder:text-[13px]  placeholder:font-normal placeholder:text-[#B1B1B1] focus-visible:border-sablack"
                        />
                    </fieldset>
                    <Table className='mb-64'>
                        <Table.Head>
                            <Table.HeadCell>Product</Table.HeadCell>
                            <Table.HeadCell>Stock</Table.HeadCell>
                            <Table.HeadCell>Total</Table.HeadCell>
                        </Table.Head>
                        <Table.Body>
                            {stockCheck?.stockQuantities.map((stockQuantity, index) => {
                                if (
                                    search &&
                                    !stockQuantity.product.title.toLowerCase().includes(search.toLowerCase()) &&
                                    !stockQuantity.product.code.toLowerCase().includes(search.toLowerCase())
                                ) {
                                    return <></>
                                }
                                return <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800" key={stockQuantity.id}>
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white">
                                        <span className='text-sared'>{stockQuantity.product.code}</span> <br></br>
                                        {stockQuantity.product.title} <br></br>
                                        {
                                            stockQuantity.packSize ? <>
                                                <span className='text-sared'>Pack Size:</span> <br></br>
                                                {stockQuantity.packSize}
                                            </> : <></>
                                        }

                                    </Table.Cell>
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white min-w-[160px]">
                                        <TextInput sizing="sm" type='number' step={0.01} onChange={(event) => {
                                            saveQuantity(stockQuantity, event.target.value ? parseFloat(event.target.value) : 0, index);
                                        }} defaultValue={stockQuantity?.quantity ?? 0} onFocus={(event) => {
                                            if(event.target.value === '0'){
                                                event.target.value = '';
                                            }
                                        }}
                                        />
                                    </Table.Cell>
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white min-w-[120px]">
                                        {stockQuantity.totalPrice ?? 0}
                                    </Table.Cell>
                                </Table.Row>
                            })}
                        </Table.Body>
                    </Table>

                    <div className='fixed bottom-0 start-0 w-full bg-gray-200 bg-gradient-to-t flex justify-between items-center p-3'>
                        <div>
                            <span className='text-sared'>Total: {totalPrice}</span>
                        </div>
                        <Button onClick={() => {
                            navigate(`/unit/${stockCheck?.unit.id}`)
                            Swal.fire(
                                'Success',
                                'Stock Check completed successfully',
                                'success'
                            );
                        }} className='border-2 rounded-full border-sagreen bg-sagreen'>
                            <div className='flex items-center justify-center'>
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-check" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 12l5 5l10 -10" /></svg>
                                <span>Finish</span>
                            </div>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StockCheckPage;